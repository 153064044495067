<template>
  <b-form-group
    label="Üretim Faaliyetleri"
    label-for="customer_activities"
  >
    <validation-provider
      #default="{ errors }"
      name="Üretim Faaliyetleri"
      rules="required"
    >
      <v-select
        id="customer_activities"
        v-model="dataItem.activities"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
        multiple
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Sectors',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
    dataList() {
      return this.$store.getters['activities/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getDataList() {
      this.$store.dispatch('activities/getDataList', {
        select: [
          'activities.id AS id',
          'activities.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
