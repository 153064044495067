<template>
  <div>
    <public />
    <related-persons />
    <activities />
    <machine-parkour />
    <billing-information />
  </div>
</template>

<script>
import Public from '@/views/Admin/Customers/CustomerForm/Public.vue'
import RelatedPersons from '@/views/Admin/Customers/CustomerForm/RelatedPersons.vue'
import Activities from '@/views/Admin/Customers/CustomerForm/Activities.vue'
import MachineParkour from '@/views/Admin/Customers/CustomerForm/MachineParkour.vue'
import BillingInformation from '@/views/Admin/Customers/CustomerForm/BillingInformation.vue'

export default {
  name: 'CustomerForm',
  components: {
    Public,
    RelatedPersons,
    Activities,
    MachineParkour,
    BillingInformation,
  },
}
</script>
