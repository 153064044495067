<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>Yetkili Kişiler</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row
        v-for="(person,key) in dataItem.related_persons"
        :key="key"
        class="d-flex align-items-center"
      >
        <b-col
          v-if="key > 0"
          cols="12"
        >
          <hr>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="12">
              <related-person-types :item-key="key" />
            </b-col>
            <b-col>
              <name :item-key="key" />
            </b-col>
            <b-col>
              <phone :item-key="key" />
            </b-col>
            <b-col>
              <email :item-key="key" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="flat-primary"
            class="btn-icon"
            pill
            :disabled="key === 0"
            @click="removeRow(key)"
          >
            <FeatherIcon icon="TrashIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <b-button
        size="sm"
        variant="primary"
        @click="addRow"
      >
        <FeatherIcon icon="PlusIcon" />
        Kişi Ekle
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardFooter, BButton,
} from 'bootstrap-vue'
import RelatedPersonTypes from '@/views/Admin/Customers/elements/RelatedPerson/RelatedPersonTypes.vue'
import Name from '@/views/Admin/Customers/elements/RelatedPerson/Name.vue'
import Phone from '@/views/Admin/Customers/elements/RelatedPerson/Phone.vue'
import Email from '@/views/Admin/Customers/elements/RelatedPerson/Email.vue'

export default {
  name: 'RelatedPersons',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BButton,
    RelatedPersonTypes,
    Name,
    Phone,
    Email,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
  },
  methods: {
    addRow() {
      this.dataItem.related_persons.push({
        id: null,
        name: null,
        phone: null,
        email: null,
      })
    },
    removeRow(key) {
      this.dataItem.related_persons.splice(key, 1)
    },
  },
}
</script>
